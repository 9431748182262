import * as _ from 'lodash';

import {PayCode, PaycodeObject} from './pay-code.model';
import {Startcard} from './start-card.model';
import {Splits} from './split-coding.model';
import {ApiInvoiceStatus} from './paymaster-batch.model';
import {CustomSickPayCode} from './project-setting.model';
import {GrossPay} from './htg-timecard.submodel';

/**
 * Enumerations
 * */

export enum TimecardStatus {
  O = 1,
  S = 2,
  D = 3
}
export enum TimecardStatusFilter {
  OPEN = 'O',
  SUBMITTED = 'S'
}
export enum WorkflowApprovalStatus {
  NEW = 'NEW',
  ROUTED = 'ROUTED',
  DECLINED = 'DECLINED',
  REROUTED = 'REROUTED',
  RESET = 'RESET',
  APPROVED = 'APPROVED'
}

export enum WorkConditionEnum {
  Studio = 'Studio',
  Distant = 'Distant',
  Bus_To_Nearby = 'Bus To',
  Report_To = 'Report To',
  Overnight = 'Overnight'
}
export enum TimecardTypes {
  CREW = 'CREW',
  SAG = 'SAG'
}
export enum PayTypeEnum {
  GUAR = 1,
  MIN = 2,
  ACT	= 3,
  IDLE	=	4,
  TRV4	=	5,
  TRV8	=	6,
  NOPAY	=	7,
  HOL8	=	8,
  HOL12	= 9,
  SICK4	= 10,
  SICK8	= 11
}
export enum DayTypeEnum {
  SIXTH	=	1,
  SEVENTH	=	2,
  HOL	=	3,
  HOLNWK =	4,
  HOLD	=	5,
  TRV	=	6,
  TRVHOL	=	7,
  TRV7TH	=	8,
  SICK	=	9,
  VAC	=	10
}

export enum MealPenaltyEnum {
  No = 'NO',
  Yes = 'YES',
  Grace = 'GRACE',
  ThirtyMinWrapExt = 'THIRTY_MIN_WRAP_EXT'
}

export enum ForceCallEnum {
  No = 'NO',
  Yes = 'YES',
  Reduced = 'REDUCED'
}

export enum ErrorType {
  SUBMITTABLE = 'SUBMITTABLE',
  NOT_SUBMITTABLE = 'NOT_SUBMITTABLE',
  AMOUNT_MISMATCH = 'AMOUNT_MISMATCH',
  DISCREPANCY = 'DISCREPANCY', // This is for UI only and won't get serialized to API
  HARD_ERROR = 'HARD_ERROR', // This is for UI only and won't get serialized to API
  SOFT_ERROR = 'SOFT_ERROR' // This is for UI only and won't get serialized to API
}
export function ErrorTypeFromString(errorTypeString: string): ErrorType {
  switch (_.toUpper(errorTypeString)) {
    case 'SUBMITTABLE':
      return ErrorType.SUBMITTABLE;
    case 'NOT_SUBMITTABLE':
      return ErrorType.NOT_SUBMITTABLE;
    case 'AMOUNT_MISMATCH':
      return ErrorType.AMOUNT_MISMATCH;
    default:
      console.log(`[ErrorTypeFromString] Unsupported Type: ${errorTypeString}`);
      return ErrorType.NOT_SUBMITTABLE;
  }
}


export enum CommonPunchTypes {
  ndb1Out  = 'ndb1Out',
  ndb1In   = 'ndb1In',
  meal1Out = 'meal1Out',
  meal1In  = 'meal1In',
  meal2Out = 'meal2Out',
  meal2In  = 'meal2In',
}
export enum SagPunchTypes {
  makeupWardrobeIn      = 'makeupWardrobeIn',
  onSet                 = 'onSet',
  dismiss               = 'dismiss',
  makeupWardrobeOut     = 'makeupWardrobeOut',
  leaveForLocation = 'leaveForLocation',
  arriveOnLocation = 'arriveOnLocation',
  leaveLocation = 'leaveLocation',
  arriveStudio = 'arriveStudio',
}
export enum CrewPunchTypes {
  call = 'call',
  ndb2Out = 'ndb2Out',
  ndb2In = 'ndb2In',
  wrap = 'wrap',
  last = 'last'
}

// This is the PunchTypes 'enum' that includes all different types of PunchTypes. We use these enums as keys in IPunch interface
export const PunchTypes = {...CommonPunchTypes, ...SagPunchTypes, ...CrewPunchTypes};

/**
 * Interfaces
 * */
export type IPunch = {
  [key in keyof typeof PunchTypes]?: ValueError<number>
};

export interface Punch extends IPunch {
  shiftId?: string;
}

export interface Punches {
  [shiftId: string]: Punch;
}

export type PunchValues = { shiftId?: string; } & {
  [K in keyof Omit<Punch, 'last'|'shiftId'>]?: number;
};

export type DetailValues = { shiftId?: string; } & {
  [K in keyof Pick<CrewDetail, 'dayType1'|'dayType2'|'payType'>]?: string;
};

export type AccountCoding = AccountSplit | AccountCode;
export function IsSplitCoding(accountCoding: AccountCoding): accountCoding is AccountSplit {
  if (!accountCoding) {
    return true;
  }
  return (<AccountSplit>accountCoding).splits !== undefined;
}

export interface AccountCodes {
  [shiftId: string]: AccountCoding;
}
export interface AccountCode {
  shiftId: string;
  glShortcutId: string;
  segments: ValueError<string>[];
}
export interface AccountSplit {
  shiftId: string;
  splits: ValueError<Splits>;
  breakages?: SplitBreakage;
}
export interface IDisplayError {
  errorMessage?: string;
  errorType?: ErrorType;
}

export interface ValueError<T> extends IDisplayError {
  value?: T;
}
export function InstanceOfIDisplayError(object: any): object is IDisplayError {
  return 'errorMessage' in object || 'errorType' in object;
}

export const IsValidationError = (obj: any): obj is ValidationError => _.isString(obj.area) && Object.values(ErrorType).includes(obj.errorType);
export interface ValidationError {
  area: string;
  field: string;
  message: string;
  errorType: ErrorType;
  entityId: string;
}

export type WorkDetail = CrewDetail|SagDetail;

export interface WorkDetails {
  [shiftId: string]: WorkDetail;
}
export interface CommonDetail {
  cityCode: ValueError<string>;
  countryCode: ValueError<string>;
  countyCode: ValueError<string>;
  dayType2: ValueError<string>;
  forceCall: ForceCallEnum;
  mealPenalty1: MealPenaltyEnum;
  mealPenalty2: MealPenaltyEnum;
  nightPremium: boolean;
  payType: ValueError<string>;
  shiftId: string;
  stateCode: ValueError<string>;
  workCondition: ValueError<string>;
}
export interface CrewDetail extends CommonDetail {
  dayType1: ValueError<string>;
  offProd: boolean;
  outZone: string;
}

export const IsCrewDetail = (obj: any): obj is CrewDetail =>
  _.isString(obj?.dayType1?.value) || _.values(ErrorType).includes(obj?.errorType);
export const GetCrewDetail = (workDetail: Omit<WorkDetail, 'shiftId'>): CrewDetail => {
  if (IsCrewDetail(workDetail)) {
    return workDetail;
  }
  throw new Error(`Trying to use SagDetails as CrewDetail`);
};

export interface SagDetail extends CommonDetail {
  notPhotographed: boolean;
}
export const IsSagDetail = (obj: any): obj is SagDetail =>
  _.isBoolean(obj?.notPhotographed);
export const GetSagDetail = (workDetail: Omit<WorkDetail, 'shiftId'>): SagDetail => {
  if (IsSagDetail(workDetail)) {
    return workDetail;
  }
  throw new Error(`Trying to use Crew Detail as SagDetail`);
};
export interface ShiftRates {
  [shiftId: string]: ShiftRate;
}

export interface ShiftRate {
  shiftId: string;
  jobCode: ValueError<string>;
  scheduleCode: ValueError<string>;
  rerateAmount: ValueError<number>;
  jobId?: string;
  scheduleId?: string;
}

export interface ShiftDates {
  [shiftId: string]: ShiftDate;
}
export interface ShiftDate {
  shiftId: string;
  mobileTimeId?: string;
  dailyTimeId?: string;
  date: string; // date format: MM-DD-YY
  splitShiftOrdinal?: number;
}

export interface JobDetail {
  unionId: string;
  jobId: string;
  jobCode: string;
  name: string;
}

export interface PayFactorTotal {
  payFactor: number;
  originalTotal?: number;
  total: number;
}

export interface PayFactor {
  payFactor: number;
  duration: ValueError<number>;
  originalHours?: number;
  rate?: number;
  payCode?: string;
  flatAmount?: number;
  isFlatAmount?: boolean;
  payCodeDetails?: PayCode;
  payCodeObject?: PaycodeObject;
  fullDuration?: number;
}

export interface Breakage {
  shiftId: string;
  hoursPaid: number;
  hoursWorked: number;
  payFactors?: PayFactor[];
  totalAmount: number;
  MP1Count: number;
  MP1Amount: number;
  MP2Count: number;
  MP2Amount: number;
  isRestViolation: boolean;
  originalHoursPaid?: number;
  originalTotalAmount?: number;
}

export interface Breakages {
  [shiftId: string]: Breakage;
}

export interface BreakageTotals {
  hoursPaid: number;
  hoursWorked: number;
  payFactors?: PayFactorTotal[];
  grandTotal: number;
  originalHoursPaid?: number;
  originalGrandTotal?: number;
  MP1Count: number;
  MP1Amount: number;
  MP2Count: number;
  MP2Amount: number;
}
export interface HoursWorked {
  [shiftDate: string]: number;
}
export interface HoursPaid {
  [shiftDate: string]: number;
}
export interface RestViolation {
  [shiftDate: string]: boolean;
}
export interface BreakageMealPenalties {
  [shiftDate: string]: {
    mealPenalty1Count: number;
    mealPenalty2Count: number;
    mealPenalty1Amount: number;
    mealPenalty2Amount: number;
  };
}
// TODO: Find a better home for SAG only fields like WorkStatuses and StuntAdjustments.
//  If we are putting them in a separate object, we should name it properly.
//  Should we use this new object in Shifts to accommodate all the unique fields for a specific type of timecard?
//  If that is the approach, then we should give it a more appropriate name
export interface WorkStatus {
  shiftId: string;
  workStatuses?: ValueError<ValueError<string>[]>;
  // TODO: HACK - Just adding stuntAdjustment here as these are all SAG specific field and we do not want to change Crew code as much as possible
  stuntAdjustment?: ValueError<number>;
}
export interface WorkStatuses {
  [shiftId: string]: WorkStatus;
}
export interface Shifts {
  shiftDates: ShiftDates;
  shiftRates?: ShiftRates;
  workDetails: WorkDetails;
  accountCodes?: AccountCodes;
  breakages?: Breakages;
  payPunches?: Punches;
  prPunches?: Punches;
  mobilePunches?: Punches;
  workStatuses?: WorkStatuses;
}

// Summary models

export interface SummaryPayDetail {
  summaryId: string;
  ordinal: number;
  payCode: ValueError<string>;
  payType1: ValueError<string>; // Pay Category
  payType2: ValueError<string>; // Pay Detail
  payCodeDetails?: PayCode; // PayCode metadata for reports
  payFactor: number;
  hourlyRate: number;
  hours: ValueError<number>;
  amount: ValueError<number|null>;
  lastCalculatedFLSAAmount?: ValueError<number|undefined>;
}

export interface SummaryPayDetails {
  [summaryId: string]: SummaryPayDetail;
}

export enum InternalUserRole {
  PAY_MASTER = 'PAYMASTER',
  PAY_MASTER_MANAGER = 'PAYMASTERMANAGER',
  PROJECT_SETUP = 'PROJECTSETUP',
  PROJECT_SETUP_MANAGER = 'PROJECTSETUPMANAGER',
  SUPPORT = 'SUPPORT'
}

export interface SummaryJobDetail {
  summaryId: string;
  ordinal: number;
  jobCode: ValueError<string>;
  scheduleCode: ValueError<string>;
  jobTitle: string;
  scheduleDescription: string;
}

export interface SummaryJobDetails {
  [summaryId: string]: SummaryJobDetail;
}

export interface SummaryDates {
  [summaryId: string]: SummaryDate;
}
export interface SummaryDate {
  summaryId: string;
  ordinal: number;
  date: ValueError<string>;
}

export interface SummaryBasics {
  summaryId: string;
  ordinal: number;
  isFlatItem: boolean;
  isUserItem: boolean;
  isHTGItem: boolean;
  isTemplateItem: boolean;
  isFLSAItem: boolean;
  isShieldItem: boolean;
  isDailyTimeAllowance: boolean;
  isStartcardAllowance: boolean;
  isPaymasterEditedLineItem: boolean;
  isUseSeparateCheckCode?: boolean;
  checkCode: string;
  state: ValueError<string>;
  county: ValueError<string>;
  city: ValueError<string>;
  country: ValueError<string>;
  workDay: number;
  taxDay: number;
}


export interface SummaryBasicsList {
  [summaryId: string]: SummaryBasics;
}

export interface SummaryAccountCodes {
  [summaryId: string]: SummaryAccountCode;
}

export interface SummaryAccountCode {
  summaryId: string;
  ordinal: number;
  segments: ValueError<string>[];
}

export interface SummaryItems {
  dates: SummaryDates;
  basics: SummaryBasicsList;
  accountCodes: SummaryAccountCodes;
  jobDetails: SummaryJobDetails;
  payDetails: SummaryPayDetails;
  previousValues: PreviousSummaryValues;
  // isFlatLineItem: boolean;
  // separateCheckCode: string;
  // workCondition: WorkCondition;
}

export interface PreviousSummaryValues {
  [summaryId: string]: PreviousSummaryLine;
}
export interface PreviousSummaryLine {
  user: string;
  summaryId: string;
  prevHtgSummaryLine?: FlatSummaryLine;
}

export enum TimecardErrorMessages {
  BreakageOverride = 'This timecard contains overrides to the original breakage.',
  VisaExpirationSubmittable = 'Timecard Warning - Check Visa expiration date before submitting Timecard',
  VisaExpirationUnsubmittable = 'Timecard Error - Visa expiration date has passed, please contact your Paymaster'
}
export interface Batch {
  id: string;
  name: string;
  status: string;
  payPeriodEnding: string;
}
export enum ApprovalStatus {
  COMPLETED = 'Completed',
  PENDING = 'Pending'
}
export interface ApprovalCircle {
  status: ApprovalStatus;
  circleDisplay: string;
}
export interface ApprovalTask extends ApprovalCircle {
  // displayText: string;
  // taskDefinitionKey: string;
  taskInstanceId: string;
  // taskName: string;
  role: string;
  // executionId: string;
  // activityInstanceId: string;

  // startTime: string;
  // endTime?: string;
  firstName: string;
  lastName: string;
/*
  status: ApprovalStatus;
  circleDisplay: string;
*/
  assigneeEntityId: string;
  // tenantId: string;
  // rootProcessInstanceId: string;
}
export interface ApprovalTaskLite {
  timecardId: string;
  taskInstanceId: string;
}
export enum MobileApprovalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING'
}
export const GetApprovalTaskByRole = (approvalTasks: ApprovalTask[], roleId: string, workflowIds: string[]): ApprovalTask | null => {
  if (_.isEmpty(approvalTasks) || (_.isEmpty(roleId) && _.isEmpty(!workflowIds)) || (roleId === '' && workflowIds.length === 0)) {
    return null;
  }
  const firstPendingTask: ApprovalTask = _
    .chain(approvalTasks)
    .filter((task: ApprovalTask) => (( _.includes(workflowIds, task.assigneeEntityId)
                                    || roleId === task.assigneeEntityId) && task.status === ApprovalStatus.PENDING) && ( !_.isEmpty(task.taskInstanceId)))
    .head()
    .value();
  if (_.isEmpty(firstPendingTask)) {
    return null;
  }
  return firstPendingTask;
};

export interface Timecard {
  timecardId: string;
  weekEndingDate: string;
  memo: string;
  status: TimecardStatus;
  shifts: Shifts;
  startcard: Startcard;
  breakageTotals?: BreakageTotals;
  summaryItems: SummaryItems;
  errors?: ValidationError[];
  isLayoffTimecard: boolean;
  showUnsubmillableTopBanner?: boolean;
  showUnsubmillableLowerBanner?: boolean;
  batch?: Batch;
  checkComment: string;
  holidayPayCodeGroup: string;
  breakWeeklyAsDaily: boolean;
  effectiveMPDate: string;
  processDefinitionId?: string;
  processInstanceId?: string;
  approvalTasks: ApprovalTask[];
  isUnsubmittable: boolean;
  approvalReroute: boolean;
  isDisplayOrangeCircle: boolean;
  workflowApprovalStatus: WorkflowApprovalStatus;
  isApplyOtherDataSource: boolean;
  createdBy: string;
  isInternal: boolean;
  hasOnlyCheckCommentsError?: boolean;
  hasConsequentialChanges?: boolean;
  isRevised: boolean;
  revisedBy: string;
  revisedTimestamp: string;
  isMobileApproved: boolean;
  invoiceStatus?: ApiInvoiceStatus;
  customSickPayCode: CustomSickPayCode;
  isMobileTimecard?: boolean;
  isDailyTimecard?: boolean;
  rawBreakages?: GrossPay;
  createdTimestamp: string;
  modifiedTimestamp: string;
  isWeekendRestOverride: boolean;
  weeklyMobileTimeId?: string;
  timecardType: TimecardTypes;
  mobileApprovalLoops?: MobileApprovalLoop[];
  perDiemDayCount?: ValueError<number>;
}

export interface DayType {
  dayTypeCode: string;
  description: string;
  ordinal: number;
  type: number;
  timecardType: string;
}
export interface PayType {
  payTypeCode: string;
  description: string;
  ordinal: number;
  timecardType: string;
  infoText?: string; // This field is used in UI for SAG payType option TRAVEL ALLOWANCE.
}

export interface TimecardLookupData {
  dayType1: DayType[];
  dayType2: DayType[];
  payType: PayType[];
}
export interface MealPenalty {
  amount: number;
  occurrenceTime: string;
  payCode: string;
}
export interface SplitBreakage {
  shiftId: string;
  punches: Punch;
  workDetails: CrewDetail;
  breakages: SimpleBreakage[];
  mealPenalties: MealPenalty[];
  totalAmount: number;
  totalMealPenalties: number;
  nonMPTotalAmount: number;
  shiftRate: number;
}
export interface SimpleBreakage {
  payFactor: number;
  duration: number;
  rate: number;
  amount: number;
  payCode: string;
  isFlatAmount: boolean;
}

export interface LiteTimecard {
  timecardId: string;
  startcardId: string;
  weekEndingDate: string;
  firstName: string;
  lastName: string;
  ssn: string;
  fein: string;
  corpName: string;
  timecardType: TimecardTypes;
}

export function breakageHours(splitBreakage: SplitBreakage): number {
  if (!splitBreakage) {
    return 0;
  }
  return _.sumBy(splitBreakage.breakages, (breakage: SimpleBreakage) => {
    return breakage.duration;
  });
}
export function breakageMealPenalty(splitBreakage: SplitBreakage): number {
  if (!splitBreakage) {
    return 0;
  }
  return _.sumBy(splitBreakage.mealPenalties, (mealPenalty) => {
    return mealPenalty.amount;
  });
}

// for showing summaryLineItems in modal
export interface FlatSummaryLine {
  summaryId: string;
  date: ValueError<string>;
  isFlatItem: boolean;
  isUserItem: boolean;
  isShieldItem: boolean;
  isHTGItem: boolean;
  isTemplateItem: boolean;
  isFLSAItem: boolean;
  isStartcardAllowance: boolean;
  checkCode: string;
  state: ValueError<string>;
  county: ValueError<string>;
  city: ValueError<string>;
  country: ValueError<string>;
  workDay: number;
  taxDay: number;
  segments: ValueError<string>[];
  jobCode: ValueError<string>;
  scheduleCode: ValueError<string>;
  jobTitle: string;
  scheduleDescription: string;
  payCode: ValueError<string>;
  payType1: ValueError<string>;
  payType2: ValueError<string>;
  payFactor: number;
  hourlyRate: number;
  hours: ValueError<number>;
  amount: ValueError<number>;
}

export interface SummaryLineChanges {
  user: string;
  date: string;
  modalLines: FlatSummaryLine[];
  changes: {[key: string]: {isEqual: boolean}};
}

export enum SplitType {
  byHour = 'Hour',
  byPercentage = 'Percentage'
}

export interface DuplicateTimecard {
  retainTimecardNotes: boolean;
  retainTimecardData: boolean;
  weekEndingDate: string;
}

export interface DuplicateTimecardResponse {
  duplicateTimecardId: string;
  startcardId: string;
  projectId: string;
}

export interface MobileApproval {
  shiftId: string;
  deptAdminApprovalId: string;
  deptAdminApprovalStatus: MobileApprovalStatus;
  deptAdminApprovalFirstName: string;
  deptAdminApprovalLastName: string;
  crewApprovalId: string;
  crewApprovalStatus: MobileApprovalStatus;
  crewApprovalFirstName: string;
  crewApprovalLastName: string;
  approvalStatus: string; // 'WAITING_FOR_CREW'|'WAITING_FOR_DEPT'|'APPROVED';
}
export interface MobileShifts extends Shifts {
  approvals: {
    [shiftId: string]: MobileApproval;
  };
}
export interface BulkActionError {
  errorCode: string;
  errorMessage: string;
  errorType: string;
}
export interface TimecardBulkActionResponse {
  errorCount: number;
  projectId: string;
  bulkActionErrors: BulkActionError[];
}

export interface SummaryAmounts {
  wagesAmount: number;
  deductionAmount: number;
  otherAmount: number;
  totalAmount: number;
}

/**
 * Utility Functions
 * */
export const FindShiftIdByWorkDate = (shiftDates: ShiftDates, workDate: string): string => _
  .chain(shiftDates)
  .values()
  .filter(({date}) => date === workDate)
  .map('shiftId')
  .head()
  .value();

export interface Discrepancy {
  entityId: string;
  punchType?: string;
  workDetailsType?: string;
}

export interface IndividualDiscrepancyDetails {
  payTimeToMobileTimeDiscrepancies: Discrepancy[];
  dailyTimeToPayTimeDiscrepancies: Discrepancy[];
  dailyTimeToMobileTimeDiscrepancies: Discrepancy[];
  hasMobileTimeDiscrepancy: boolean;
}

export interface ReturnToCrewPayload {
  notes: string;
}

export interface ProcessedMobileShift {
  mobiletimeId: string;
  workDate: string;
  timecardShiftId: string;
}

export interface ProcessedMobileShifts {
  [timecardId: string]: ProcessedMobileShift[];
}

export interface MobileApprovalLoop {
  initials: string;
  isGreen: boolean;
  ordinal: number;
  approver: string;
  hasMobileDiscrepancy: boolean;
}
export interface MobileApprovalCircle {
  approverId: string;
  status: MobileApprovalStatus;
  circleDisplay: string;
  hasMobileDiscrepancy: boolean;
}
