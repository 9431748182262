import * as _ from 'lodash';

export const TimecardApiPunchTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
export const StandardDateFormatString = 'MM-DD-YYYY';
export const URLDateFormatString = 'MM/DD/YY';
export const StandardDateTimeFormatString = 'MM-DD-YYYY HH:mm:ss';
export const NewSummaryLineIdPrefix = 'temp-';
export const FirstSummaryLineId = `${NewSummaryLineIdPrefix}0`;
export const DefaultSummaryLineIndex = 1;
export enum Area {
  Shift = 'SHIFT',
  Summary = 'SUMMARY',
  Timecard = 'TIMECARD'
}
export enum Field {
  Call = 'call',
  Wrap = 'wrap',
  Last = 'last',
  Ndb1Out = 'ndb1Out',
  Meal1Out = 'meal1Out',
  Ndb2Out = 'ndb2Out',
  Meal2Out = 'meal2Out',
  Ndb1In = 'ndb1In',
  Meal1In = 'meal1In',
  Ndb2In = 'ndb2In',
  Meal2In = 'meal2In',
  Date = 'date',
  Country = 'country',
  State = 'state',
  City = 'city',
  County = 'county',
  Job = 'job',
  Schedule = 'schedule',
  Rerate = 'rerate',
  Amount = 'amount',
  Paycode = 'paycode',
  PayType1 = 'payType1',
  PayType2 = 'payType2',
  CountryCode = 'countryCode',
  StateCode = 'stateCode',
  CityCode = 'cityCode',
  CountyCode = 'countyCode',
  ErrorBanner = 'errorBanner',
  SummaryErrorBanner = 'summaryErrorBanner',
  DayType = 'dayType',
  PayType = 'payType',
  DayType1 = 'dayType1',
  DayType2 = 'dayType2',
  Hours = 'hours',
  SplitAccountCoding = 'split-account-coding',
  WorkStatuses = 'workStatuses',
  StuntAdjustment = 'stuntAdjustment',
  WorkCondition = 'workCondition',
  PerDiemDayCount = 'PerDiemDayCount',
  HTG = 'HTG'
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export const DistanceFromSaturday = (target: DayOfWeek): number => {
  return 6 - target.valueOf();
};


export interface CheckCode {
  value: string;
  title: string;
  position: number;
  isAllowance: boolean;
}
export const CheckCodes: CheckCode[] = [
  { value: 'A', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: true, position: 0},
  { value: 'B', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: true, position: 1},
  { value: 'C', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: true, position: 2},
  { value: 'D', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: true, position: 3},
  { value: 'E', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: true, position: 4},
  { value: 'F', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: true, position: 5},
  { value: 'G', title: 'All deductions will be taken',                              isAllowance: false, position: 6},
  { value: 'H', title: 'All deductions will be taken',                              isAllowance: false, position: 7},
  { value: 'I', title: 'All deductions will be taken',                              isAllowance: false, position: 8},
  { value: 'J', title: 'All deductions will be taken',                              isAllowance: false, position: 9},
  { value: 'K', title: 'All deductions will be taken',                              isAllowance: false, position: 10},
  { value: 'L', title: 'All deductions will be taken',                              isAllowance: false, position: 11},
  { value: 'R', title: 'All deductions will be taken',                              isAllowance: false, position: 12},
  { value: 'S', title: 'All deductions will be taken',                              isAllowance: false, position: 13},
  { value: 'T', title: 'All deductions will be taken',                              isAllowance: false, position: 14},
  { value: 'U', title: 'All deductions will be taken',                              isAllowance: false, position: 15},
  { value: 'V', title: 'All deductions will be taken',                              isAllowance: false, position: 16},
  { value: 'W', title: 'All deductions will be taken',                              isAllowance: false, position: 17},
  { value: 'X', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: false, position: 18},
  { value: 'Y', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: false, position: 19},
  { value: 'Z', title: 'No voluntary deductions (e.g. 401K, direct deposit, etc.)', isAllowance: false, position: 20}
];
// remove the first 6 (A-F) from check code to get G-Z
export const WageCheckCodesForAutoAssignment = (): string[] => _
  .chain(CheckCodes)
  .drop(6)
  .map(({value}) => value)
  .value();
export const CheckCodesForAutoAssignment = (): string[] => {
  const checkCodes = _.map(CheckCodes, 'value');
  const aToF = checkCodes.slice(0, 6);
  const gToZ = checkCodes.slice(6, checkCodes.length);
  return [...gToZ, ...aToF];
};
export const CheckCodeForStartcardAllowanceAssignment = (): string[] => {
  const checkCodes = _.map(CheckCodes, 'value');
  return checkCodes.slice(0, 6);
};

export const uuidRegex = '[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}';
export const militaryTimeRegex = '([0-1][0-9]|2[0-3]):?([0-5][0-9])';
export const payrollDateFormat = 'MM/DD/YYYY';
export const militaryTimeFormat = 'HH:mm';
export const utcTimestampFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZZ';
export const minDateYear = 1900;
export const maxDateYear = 3001;

export const defaultSummaryLineSortOrder = ['checkCode', 'workDate', 'accountCodeString', 'payCode'];

export const CAOT_PAYCODE = '271';
export const ExcludedListOfUnionEPCodeInitialsForRerate = ['U', 'V', 'W', 'X', 'Y', 'Z'];
export const NonUnionEPCodeInitials = ['U', 'V', 'X', 'Y', 'Z'];
export const NonUnionEPCodeInitialsWithoutNonAffilCrew = ['U', 'X', 'Z'];
export const EPCodeInitialsForSAG = ['S', 'A'];
export const BLANK_FILTER = 'Blank';
// ToDo : We have to find and use below const value for the all DefaultExternalDiscrepancy value, instead of hard coding, will covered in STF-23248
export const DefaultExternalDiscrepancy = {discrepancies: [], isMobileApproved: false, timecardId: '', mobileApprovalLoops: []};
export enum Region {
  USA = 'USA',
  GBR = 'GBR'
}

export const PerDiemDayPayCodes = ['861', '860', '881', '878'];
