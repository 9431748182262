import * as _ from 'lodash';

import {Paymaster} from '../common/paymaster.model';
import {BatchSyncLockStatus, PaymasterBatchDetails, PayrollEvent} from '../common/paymaster-batch.model';
import {GridColumn} from '../client/app/shared/components/grid-column-filter/grid-column-filter-view.model';
import {PaymasterSearchTypeModel} from '../client/app/paymaster/models/paymaster-batches-view.model';
import {BatchStatus} from '../common/batch.model';
import {ColumnConfig} from '../client/app/shared/components/manage-column/manage-column.model';
import {TitleOption} from '../client/app/shared/components/dropdown-filter/dropdown-filter.model';
import {ReportSortOptions} from '../client/app/timecard/models/timecard-summary-view.model';
import {GtnEditReport} from '../common/report.model';
import {FilterOption} from '../client/app/shared/components/filter-panel/filter-item.model';
import {TextFilterConfig} from '../client/app/shared/components/text-filter/text-filter.model';

export const PaymasterBatchFilterTypes = {
  'paymaster': { label: 'Paymaster', value: 'paymasters' },
  'paymaster_manager': { label: 'Paymaster Manager', value: 'paymaster_managers' }
};

export namespace PaymasterBatchConstants {
  export class PaymasterBatch {
    public static get DefaultBatchPaymasterForRelease(): Paymaster {
      return {id: 0, firstName: 'Client', lastName: '', initials: 'TCI', phone: '', email: ''};
    }

    public static get DefaultPayrollEvent(): Partial<PayrollEvent> {
      return {
        batchKeyedBy: '',
        editCheckedBy: '',
        editSentDetail: {
          editSentBy: '',
          editSentDate: '',
          editSentTime: '',
          editSentTimestamp: ''
        },
        editApprovedDetail: {
          editApprovedBy: '',
          editApprovedDate: '',
          editApprovedTime: '',
          editApprovedTimestamp: ''
        },
        releasedBy: '',
      };
    }

    public static get DefaultColumnList(): ReadonlyArray<GridColumn> {
      return [
        {field: 'batchName', header: 'Batch Name'},
        {field: 'batchNotes', header: 'Batch Notes'},
        {field: 'attachments', header: 'Attachments'},
        {field: 'payPeriodEnding', header: 'Pay Period Ending'},
        {field: 'clientId', header: 'Client ID'},
        {field: 'projectName', header: 'Project Name'},
        {field: 'paymaster', header: 'Paymaster'},
        {field: 'batch', header: 'Batch #'},
        {field: 'invoice', header: 'Invoice #'},
        {field: 'timecards', header: 'Timecards'},
        {field: 'hasPayrollExceptions', header: 'VA'},
        {field: 'errors', header: 'Errors'},
        {field: 'status', header: 'Status'},
        {field: 'sumbitted', header: 'Submitted'}
      ];
    }

    public static get BatchDetailsTcSortOptions () {
      return {
        crewName: 'lastName[asc];firstName[asc];periodEndingDate[asc]',
        error: 'payrollTimecardErrors.errorMessageNotAnalyzed[asc];lastName[asc];firstName[asc];periodEndingDate[asc]',
        invoice: 'timecardInvoiceNumber[asc];lastName[asc];firstName[asc];periodEndingDate[asc]',
      };
    }

    public static get DefaultLiteBatchQueryParams() {
      return {
        filters: [
          {
            filterType: 'batchStatus',
            operator: '[eq]',
            value: [_.capitalize(BatchStatus.SUBMITTED)]
          }
        ],
        sort: 'submitDate[asc]',
        offset: 1,
        pageSize: 40,
        searchType: PaymasterSearchTypeModel.clientNumberTxt,
        searchText: '',
      };
    }

    public static get DefaultBatchDetailsTcQueryParams() {
      return {
        filters: [],
        sort: PaymasterBatch.BatchDetailsTcSortOptions.crewName,
        offset: 1,
        pageSize: 1000,
        searchType: 'nameNotAnalyzed',
        searchText: ''
      };
    }

    public static get DefaultBatchDetailsHeader(): PaymasterBatchDetails {
      return {
        id: '',
        batchName: '',
        projectId: '',
        projectName: '',
        clientId: '',
        payPeriodEnding: '',
        timecards: 0,
        batchErrorCount: 0,
        timecardErrorCount: 0,
        hasUnsubmittableErrors: false,
        hasSubmittableErrors: false,
        invoice: '',
        invoiceNumbers: [],
        batchNumbers: [],
        status: BatchStatus.UNSUBMITTED,
        paymaster: '',
        submittedDate: '',
        payrollBatchDetails: [],
        processSites: [],
        billingSites: [],
        printSites: [],
        printSite: '',
        processingSite: '',
        billingSite: '',
        payrollBatchErrors: [],
        syncLock: BatchSyncLockStatus.Unlocked,
        isInternal: false,
        payrollReceivedTime: '',
        initialSubmittedTime: '',
        documentMetadata: [],
        injectData: {
          hasPayrollExceptions: false,
          overridden: false,
          overrideReason: ''
        },
        hasPayrollExceptions: false,
      };
    }

    public static get DefaultBatchInvoiceModalConfig() {
      return {
        title: '',
        dialogText: '',
        primaryBtnText: '',
        warningMessage: '',
        invoicesToDisable: []
      };
    }

    public static get PaymasterBrowseColumnConfigs(): ColumnConfig[] {
      return [
        {field: 'batchName', header: 'Batch Name', isVisible: true, isMandatory: true, ordinal: 1},
        {field: 'batchNotes', header: 'Batch Notes', isVisible: true, isMandatory: false, ordinal: 2},
        {field: 'attachments', header: 'Attachments', isVisible: true, isMandatory: false, ordinal: 3},
        {field: 'payPeriodEnding', header: 'Pay Period Ending', isVisible: true, isMandatory: false, ordinal: 4},
        {field: 'clientId', header: 'Client ID', isVisible: true, isMandatory: false, ordinal: 5},
        {field: 'projectName', header: 'Project Name', isVisible: true, isMandatory: false, ordinal: 6},
        {field: 'payMaster', header: 'Paymaster', isVisible: true, isMandatory: false, ordinal: 7},
        {field: 'batchNumber', header: 'Batch #', isVisible: true, isMandatory: false, ordinal: 8},
        {field: 'invoiceNumber', header: 'Invoice #', isVisible: true, isMandatory: false, ordinal: 9},
        {field: 'timecards', header: 'Timecards', isVisible: true, isMandatory: false, ordinal: 10},
        {field: 'hasPayrollExceptions', header: 'VA', isVisible: true, isMandatory: false, ordinal: 11},
        {field: 'errorsCount', header: 'Errors Count', isVisible: true, isMandatory: false, ordinal: 12},
        {field: 'currentStatus', header: 'Current Status', isVisible: true, isMandatory: false, ordinal: 13},
        {field: 'submitted', header: 'Submitted', isVisible: true, isMandatory: false, ordinal: 14}
      ];
    }

    public static get DefaultPaymasterBatchFilter(): TitleOption {
      return {
        label: 'Paymaster',
        value: 'paymasters'
      };
    }

    public static get PaymasterBatchFilterTypes(): TitleOption[] {
      return _.values(PaymasterBatchFilterTypes);
    }

    public static get BatchStatusFilterOption(): FilterOption[] {
      return [
        {id: '0', value: _.capitalize(BatchStatus.SUBMITTED), type: 'batchStatus'},
        {id: '1', value: _.capitalize(BatchStatus.PROCESSED), type: 'batchStatus'}
      ];
    }

    public static get BatchPayrollExceptionsFilterOption(): FilterOption[] {
      return [
        {id: '0', value: 'Yes', type: 'hasPayrollExceptions'},
        {id: '1', value: 'No', type: 'hasPayrollExceptions'}
      ];
    }

    public static get BatchErrorFilterOption(): FilterOption[] {
      return [
        {id: '0', value: 'Yes', type: 'hasAnyErrors'},
        {id: '1', value: 'No', type: 'hasAnyErrors'}
      ];
    }

    public static get BatchTextFilterConfig(): {[key: string]: TextFilterConfig} {
      return {
        'project': {
          identifier: 'project',
          type: 'projectNameNotAnalyzed',
          title: 'Project Name',
          minSearchTextLength: 3
        },
        'invoice': {
          identifier: 'invoice',
          type: 'payrollBatchDetails.invoiceNumber',
          title: 'Invoice #',
          minSearchTextLength: 3
        },
        'status': {
          identifier: 'status',
          type: 'batchStatus',
          title: 'Current Status',
          minSearchTextLength: 2
        },
        'payroll': {
          identifier: 'payroll',
          type: 'hasPayrollExceptions',
          title: 'Payroll Exception(s)',
          minSearchTextLength: 2
        },
        'error': {
          identifier: 'error',
          type: 'hasAnyErrors',
          title: 'Error(s)',
          minSearchTextLength: 2
        }
      };
    }

    public static get GtnReportSortOptions(): ReportSortOptions[] {
      return [
        {name: 'Input Order', value: 'input order'},
        {name: 'Name', value: 'name'},
        {name: 'Loanout', value: 'Loanout'}
      ];
    }

    public  static get DefaultGtnReportOptions(): GtnEditReport {
      return {
        printOneTCPerPage: false,
        showBreakages: true,
        showRates: true,
        showAddressInfo: true,
        showStartcardNotes: true,
        showForms: true,
        showIssues: true,
        showTimeGrid: true,
        showSummaryGrid: true,
        showEmployerFringes: true,
        showEmployeeFringes: true,
        showDeductions: true,
        showNotesAndTotals: true,
        showSignatures: false,
        showIPSWarning: true,
        revisedOnly: false,
        sortBy: 'Loanout'
      };
    }

    public static get BatchActions() {
      return {
        inject: 'Inject',
        release: 'Release',
        unsubmitBatch: 'Unsubmit Batch',
        unsubmitTC: 'Unsubmit TC',
        GTNEdit: 'GTN Edit',
        enableForIPS: 'Enable For IPS'
      };
    }
  }

  export class BatchMangerDetails {
    public static get DefaultColumnList(): ReadonlyArray<GridColumn> {
      return [
        {field: 'crewName', header: 'Crew Name'},
        {field: 'ssn', header: 'SSN'},
        {field: 'fein', header: 'FEIN'},
        {field: 'weekEnding', header: 'Week Ending'},
        {field: 'jobTitle', header: 'Job Title'},
        {field: 'invoiceNumbers', header: 'Invoice #'},
        {field: 'epCode', header: 'EP Code'},
        {field: 'status', header: 'Status'},
        {field: 'vacationType', header: 'Vac'},
        {field: 'holidayType', header: 'Hol'},
        {field: 'hasPayrollExceptions', header: 'Exceptions'},
        {field: 'errors', header: 'Errors'},
        {field: 'errorNotes', header: 'Error Notes'}
      ];
    }

    public static get BatchDetailsColumnConfigs(): ColumnConfig[] {
      return [
        {field: 'crewName', header: 'Crew Name', isVisible: true, isMandatory: true, ordinal: 1},
        {field: 'ssn', header: 'SSN', isVisible: true, isMandatory: false, ordinal: 2},
        {field: 'fein', header: 'FEIN', isVisible: true, isMandatory: false, ordinal: 3},
        {field: 'weekEnding', header: 'Week Ending', isVisible: true, isMandatory: false, ordinal: 4},
        {field: 'jobTitle', header: 'Job Title', isVisible: true, isMandatory: false, ordinal: 5},
        {field: 'invoiceNumbers', header: 'Invoice #', isVisible: true, isMandatory: false, ordinal: 6},
        {field: 'epCode', header: 'EP Code', isVisible: true, isMandatory: false, ordinal: 7},
        {field: 'status', header: 'Status', isVisible: true, isMandatory: false, ordinal: 8},
        {field: 'vacationType', header: 'Vac', isVisible: true, isMandatory: false, ordinal: 9},
        {field: 'holidayType', header: 'Hol', isVisible: true, isMandatory: false, ordinal: 10},
        {field: 'hasPayrollExceptions', header: 'VA', isVisible: true, isMandatory: false, ordinal: 11},
        {field: 'errors', header: 'Errors', isVisible: true, isMandatory: false, ordinal: 12},
        {field: 'errorNotes', header: 'Error Notes', isVisible: true, isMandatory: false, ordinal: 13}
      ];
    }
  }
}
